import {
  BaseControllerContext,
  BaseWidgetController,
  Tab,
} from 'common/controllers';
import {
  IGroupRequests,
  IGroupRequestsMessage,
} from '@wix/social-groups-api/dist/src/model/GroupRequests/IGroupRequests';
import { IGroupRequestsApi } from '@wix/social-groups-api/dist/src/services/group/IGroupRequestsApi';
import {
  ApproveGroupRequestsRequest,
  ApproveGroupRequestsResponse,
  CancelGroupRequestRequest,
  CancelGroupRequestResponse,
  GroupRequest,
  ItemsToUpdate,
  ListGroupRequestsRequest,
  ListGroupRequestsResponse,
  OwnershipFilter,
  QueryGroupRequestsRequest,
  QueryGroupRequestsResponse,
  RejectGroupRequestsRequest,
  RejectGroupRequestsResponse,
  RequestStatus,
  SubmitGroupRequestRequest,
  SubmitGroupRequestResponse,
} from '@wix/ambassador-social-groups-v2-group-request/types';
import { ApiDelegate } from 'common/api/services/ApiDelegate';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { PubSubEventTypes } from 'Group/controllers/pubSub/PubSubEventTypes';
import { IFilter } from '@wix/social-groups-api/dist/src/model/QueryBuilder/QueryV2';
import { RequestState } from '@wix/social-groups-api/dist/src/services/RequestState';
import { SiteNavigation } from 'common/controllers/site-navigation/SiteNavigation';

export class GroupRequestsController
  extends BaseWidgetController<IGroupRequests>
  implements IGroupRequestsApi {
  private _api: ApiDelegate;

  constructor(controllerContext: ControllerParams) {
    super(controllerContext);
    this._api = ApiDelegate.getInstance(
      new BaseControllerContext(controllerContext),
    );
    this.onUserLogin(async () => {
      const groupRequestsResponse = await this.getGroupRequestResponse();
      this.setProps({ groupRequestsResponse });
    });
  }

  queryGroupRequests = async (
    payload: QueryGroupRequestsRequest,
  ): Promise<QueryGroupRequestsResponse | void> => {
    this.setProps({
      groupRequestsStatus: { queryGroupRequests: RequestState.PENDING },
    });
    try {
      const groupRequestsResponse = await this.getGroupRequestResponse(payload);
      this.setProps({
        groupRequestsResponse,
        groupRequestsStatus: { queryGroupRequests: RequestState.SUCCESS },
      });
    } catch (e) {
      this.setProps({
        groupRequestsStatus: { queryGroupRequests: RequestState.ERROR },
      });
    }
  };

  private getPendingGroupRequestsQuery(): QueryGroupRequestsRequest {
    const filter: IFilter<GroupRequest> = {
      status: { $eq: RequestStatus.PENDING },
    };

    return {
      ownershipFilter: this.getOwnershipFilter(),
      query: {
        filter,
      },
    };
  }

  listGroupRequests = async (
    payload: ListGroupRequestsRequest = {},
  ): Promise<ListGroupRequestsResponse | void> => {
    this.setProps({
      groupRequestsStatus: { listGroupRequests: RequestState.PENDING },
    });
    const ownershipFilter = this.getOwnershipFilter();
    payload.ownershipFilter = ownershipFilter;
    try {
      const groupRequestsResponse = await this._api
        .getGroupApi()
        .listGroupRequests(payload);
      const { groupRequests } = groupRequestsResponse || {};
      const creators = await this.getGroupRequestsCreators(groupRequests!);
      const groupUrls = await this.getGroupUrls(groupRequests!);
      this.setProps({
        groupRequestsResponse: Object.assign(groupRequestsResponse, {
          creators,
          groupUrls,
        }),
        groupRequestsStatus: { listGroupRequests: RequestState.SUCCESS },
      });
    } catch (e) {
      this.setProps({
        groupRequestsStatus: { listGroupRequests: RequestState.ERROR },
      });
    }
  };

  private getOwnershipFilter() {
    const ownershipFilter = this.isUserAdmin()
      ? OwnershipFilter.ALL
      : OwnershipFilter.CURRENT_MEMBER;
    return ownershipFilter;
  }

  approveGroupRequests = async (
    payload: ApproveGroupRequestsRequest,
  ): Promise<ApproveGroupRequestsResponse | void> => {
    const { groupRequestIds } = payload;
    if (groupRequestIds?.length) {
      payload.itemsToApprove = ItemsToUpdate.BY_ID;
      try {
        await this._api.getGroupApi().approveGroupRequests(payload);
      } catch (e) {
        this.setProps({
          groupRequestsStatus: { approveGroupRequests: RequestState.ERROR },
        });
      }
      await this.queryGroupRequests(this.getPendingGroupRequestsQuery());
      this.publish<IGroupRequestsMessage>(
        PubSubEventTypes.GROUP_REQUEST_ACTION,
        { approveGroupRequests: groupRequestIds },
      );
    }
    return;
  };
  rejectGroupRequests = async (
    payload: RejectGroupRequestsRequest,
  ): Promise<RejectGroupRequestsResponse | void> => {
    if (payload.rejections?.length) {
      payload.itemsToReject = ItemsToUpdate.BY_ID;
      try {
        await this._api.getGroupApi().rejectGroupRequests(payload);
      } catch (e) {
        this.setProps({
          groupRequestsStatus: { rejectGroupRequests: RequestState.ERROR },
        });
      }
      await this.queryGroupRequests(this.getPendingGroupRequestsQuery());
      this.publish<IGroupRequestsMessage>(
        PubSubEventTypes.GROUP_REQUEST_ACTION,
        {
          rejectGroupRequests: payload.rejections.map(
            (rejection) => rejection.groupRequestId,
          ),
        },
      );
    }
    return;
  };
  submitGroupRequest = (
    payload: SubmitGroupRequestRequest,
  ): Promise<SubmitGroupRequestResponse> => {
    throw new Error('Method not implemented.');
  };

  cancelGroupRequest = async (
    payload: CancelGroupRequestRequest,
  ): Promise<CancelGroupRequestResponse | void> => {
    try {
      await this._api.getGroupApi().cancelGroupRequest(payload);
    } catch (e) {
      this.setProps({
        groupRequestsStatus: { cancelGroupRequest: RequestState.ERROR },
      });
    }
    await this.queryGroupRequests(this.getPendingGroupRequestsQuery());
    this.publish<IGroupRequestsMessage>(PubSubEventTypes.GROUP_REQUEST_ACTION, {
      cancelGroupRequest: [payload.groupRequestId],
    });
    return;
  };

  async pageReady(): Promise<any> {
    try {
      const groupRequestsResponse = await this.getGroupRequestResponse();
      this.setProps({
        groupRequestsResponse,
        groupRequestsStatus: {
          listGroupRequests: RequestState.SUCCESS,
        },
        groupRequestsActions: {
          cancelGroupRequest: this.cancelGroupRequest,
          submitGroupRequest: this.submitGroupRequest,
          rejectGroupRequests: this.rejectGroupRequests,
          approveGroupRequests: this.approveGroupRequests,
          listGroupRequests: this.listGroupRequests,
          queryGroupRequests: this.queryGroupRequests,
        },
      });
    } catch (e) {
      this.setProps({
        groupRequestsStatus: { listGroupRequests: RequestState.ERROR },
      });
    }
    return Promise.resolve();
  }

  private async getGroupRequestResponse(
    payload?: QueryGroupRequestsRequest,
  ): Promise<IGroupRequests['groupRequestsResponse']> {
    if (!payload) {
      payload = this.getPendingGroupRequestsQuery();
    }
    if (this.isUserLoggedIn()) {
      const groupRequestsResponse = await this._api
        .getGroupApi()
        .queryGroupRequests(payload);
      const { groupRequests } = groupRequestsResponse || {};
      const creators = await this.getGroupRequestsCreators(groupRequests!);
      const groupUrls = await this.getGroupUrls(groupRequests!);
      return Object.assign(groupRequestsResponse, {
        creators,
        groupUrls,
      });
    }
    return { creators: {}, groupUrls: {} };
  }

  private async getGroupRequestsCreators(groupRequests: GroupRequest[]) {
    try {
      const ids = new Set(
        groupRequests?.map((req) => req.group?.createdBy?.id!),
      );
      const members = await this._api
        .getMembersService()
        .queryMembersByIds(Array.from(ids));

      return Object.fromEntries(members.map((m) => [m.id, m]));
    } catch (e) {
      console.log('Error in getGroupRequestsCreators');
    }
  }

  getInitialProps(): Promise<Partial<IGroupRequests>> {
    return Promise.resolve({
      groupRequestsStatus: { listGroupRequests: RequestState.PENDING },
    });
  }

  private async getGroupUrls(groupRequests: GroupRequest[]) {
    const { isViewer } = this.getEnvironment();
    if (!isViewer) {
      // navigation by url doesn't work in editor
      return {};
    }
    const navigation = new SiteNavigation(this);
    const tabName = Tab.DISCUSSION;
    const urls: [string, string][] = await Promise.all(
      groupRequests?.map(async (req) => {
        const { slug, id } = req.group!;
        const groupUrl = await navigation.getGroupUrl({
          groupId: slug!,
          tabName,
        });
        return [id!, groupUrl!] as [string, string];
      }),
    );

    return Object.fromEntries(urls);
  }
}
